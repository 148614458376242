import { graphql, Link, PageProps } from "gatsby";
import {
    GatsbyImage,
    getImage,
    getSrc,
    IGatsbyImageData,
} from "gatsby-plugin-image";
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

import Footer from "../../component/footer/footer";
import { hamburgerMenu } from "../../styles.css";
import {
    container,
    details,
    mainContainer,
    peintureContainer,
    peintureInfo,
    peintureTitle,
    pictureContainer,
} from "../../styles/peinture.css";
import "./peinture.css";

type Props = {}

type DataType = {
  painting: {
    id: string
    slug: string
    titre: string
    prix: number
    status: boolean
    dimension: string
    intentions: {
      intentions: string
    }
    technique: {
      technique: string
    }
    image: {
      title: string
      gatsbyImageData: IGatsbyImageData
    }
  }
}
const isBrowser = typeof window !== "undefined"

const peinture = ({ data: { painting } }: PageProps<DataType>) => {
  if (!isBrowser) return null
  if (!painting) return null

  const [open, setOpen] = useState(false)

  const gatsbyImage = getImage(painting.image.gatsbyImageData)

  const lbImage = {
    ...gatsbyImage,
    src: getSrc(painting.image.gatsbyImageData) || "",
    alt: painting.image.title,
  }

  if (!gatsbyImage) return null
  return (
    <div className={mainContainer}>
      <Link to="/galerie" className={hamburgerMenu}>
        Retour à la galerie
      </Link>

      <div className={peintureContainer}>
        <div className={container}>
          <div className={pictureContainer}>
            <div onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
              <GatsbyImage
                style={{ maxHeight: "500px", maxWidth: "100%" }}
                imgStyle={{ height: "100%", objectFit: "contain" }}
                image={gatsbyImage}
                alt={painting.image.title}
              ></GatsbyImage>
            </div>
            <Lightbox
              open={open}
              plugins={[Zoom]}
              close={() => setOpen(false)}
              slides={[lbImage]}
              styles={{
                container: {
                  background: "rgba(0, 0, 0, 0.9)",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              render={{
                slide: (image, offset, rect) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <GatsbyImage
                        imgStyle={{ height: "100%", objectFit: "contain" }}
                        image={gatsbyImage}
                        alt={painting.image.title}
                      />
                    </div>
                  )
                },
              }}
            />
          </div>
          <div className={peintureInfo}>
            <p className={peintureTitle}>{painting.titre}</p>
            <div className={details}>
              {painting.dimension && (
                <div>
                  <h4>Dimensions</h4>
                  <p>{painting.dimension}</p>
                </div>
              )}
              <div>
                <h4>Statut</h4>
                <p>{painting.status ? "Disponible" : "Vendu"}</p>
              </div>
              {painting.prix && (
                <div>
                  <h4>Prix</h4>
                  <p>{painting.prix}€</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default peinture

export const query = graphql`
  query ($slug: String!) {
    painting: contentfulTableau(slug: { eq: $slug }) {
      id
      slug
      titre
      prix
      status
      dimension
      image {
        title
        gatsbyImageData
      }
    }
  }
`
